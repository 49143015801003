import v from 'validator';
import {AddressStruct} from '../../types/types';

export const verifyAccountType = (type: string) => {
  if (type !== 'agency' && type !== 'independent_business')
    return {account_type: 'Account type is required'};
  else return null;
};

export const verifyTenant = (
  name: string,
  contact_name: string,
  contact_address: AddressStruct | null,
  contact_email: string,
  contact_phone: string
) => {
  let errorString = '';

  if (v.isEmpty(name)) errorString += 'Tenant Name, ';
  if (v.isEmpty(contact_name)) errorString += 'Contact Name, ';
  if (contact_address === null) errorString += 'Address, ';
  if (v.isEmpty(contact_email) || !v.isEmail(contact_email))
    errorString += 'Email, ';
  if (v.isEmpty(contact_phone)) errorString += 'Phone, ';

  errorString = errorString.substring(0, errorString.length - 2);

  if (errorString !== '')
    return {tenant: `The following field(s) are invalid/empty: ${errorString}`};
  else return null;
};

export const verifyUsers = (initial_admin_users: string[]) => {
  if (initial_admin_users.length < 1)
    return {users: 'Please provide at least 1 admin'};
  else return null;
};

export const verifyAppointmentAddress = (
  appointment_address: AddressStruct | null
) => {
  if (appointment_address === null)
    return {appointment: 'Appointment address is required'};
  else return null;
};
