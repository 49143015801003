/* eslint-disable @typescript-eslint/no-explicit-any */
import {Logout, Person} from '@mui/icons-material';
import {AuthContext} from '../../providers/AuthProvider';
import {useContext, useState} from 'react';
import {
  Box,
  IconButton,
  MenuItem,
  ListItemIcon,
  Avatar,
  Divider,
  Menu,
  Tooltip,
} from '@mui/material';

export const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {logOut} = useContext(AuthContext);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccount = (newTab: boolean) => {
    const isLocal = window.location.origin === process.env.LOCAL_INTERNAL_URL;

    const redirectBaseUrl = isLocal
      ? process.env.LOCAL_ACCOUNTS_URL
      : process.env.DS_ACCOUNTS_URL;

    const url = `${redirectBaseUrl}`;

    if (newTab) window.open(url, '_blank');
    else window.location.href = url;
  };

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <Tooltip title="Account Settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Person sx={{fontSize: '1.75em', color: 'white'}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            marginTop: '10px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem
          onClick={() => handleAccount(false)}
          onAuxClick={() => handleAccount(true)}
        >
          <ListItemIcon>
            <Avatar />
          </ListItemIcon>
          My Account
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => logOut()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
