import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {Deductions} from './Deductions';

export const DeductionsView = () => {
  return (
    <MainViewContainer title="Deduction Tables">
      <Deductions type="table" />
    </MainViewContainer>
  );
};
