import {getOpsProfile} from '@digistaff/business';
import {app, signOut} from '../Account';

export const fetchOpsProfile = async (opsId: string) => {
  try {
    const res = await getOpsProfile(app, opsId);

    return res.data.getOpsProfile;
  } catch (error) {
    return Promise.reject(error);
  }
};

const handleAuthRedirect = () => {
  const isLocal = window.location.origin === process.env.LOCAL_INTERNAL_URL;
  const redirectBaseUrl = isLocal
    ? process.env.LOCAL_ACCOUNTS_URL
    : process.env.DS_ACCOUNTS_URL;

  window.location.href = `${redirectBaseUrl}`;
};

export const logOut = () => {
  signOut(app)
    .then(() => {
      localStorage.clear();
      handleAuthRedirect();
    })
    .catch(err => console.log(err));
};

export const getCurrentLoggedOps = () => {
  try {
    if (
      app.session.lastAuthUser === null &&
      app.session.lastAuthUser === 'null' &&
      app.user.username === null &&
      app.user.username === 'null'
    ) {
      signOut(app)
        .then(() => {
          localStorage.clear();
          logOut();
        })
        .catch(err => console.log(err));
    }

    return app.session.lastAuthUser || app.user.username;
  } catch (err) {
    return err;
  }
};
