/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Grid, Stack} from '@mui/material';
import {AppBar, Drawer, DrawerHeader} from './MainViewContainerStyles';
import {NewDigistaffLogoFullWhite, NewDigistaffLogoWhite} from '../Image/Image';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from '../../providers/AuthProvider';
import {useContext, useEffect, useState} from 'react';
import {AppsMenu} from '../NavBar/AppsMenu';
import {sideBarItemList} from './sideBarItemList';
import {NestedList} from './NestedList';
import {AccountMenu} from '../NavBar/AccountMenu';

export interface MainViewContainerProps {
  title: string;
  children: React.ReactNode;
}

/**
 * Container that handles the structure and rendering of views
 *
 * @param title - Title of the page to be rendered at the top
 * @param children - JSX element to render under the Title
 */
export const MainViewContainer = ({
  title,
  children,
}: MainViewContainerProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = window.screen.width < 900;
  const [open, setOpen] = useState(!isMobile);
  const {currentUser} = useContext(AuthContext);

  useEffect(() => {
    if (currentUser.logOut) navigate('/', {replace: true});
  }, [currentUser, navigate]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{display: 'flex'}}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={2}>
                <Stack direction="row" alignItems="center" spacing={0}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      marginRight: 2,
                      ...(open && {display: 'none'}),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: {
                        xs: 'flex',
                        md: 'none',
                      },
                      marginLeft: '5px',
                    }}
                  >
                    <Box
                      component="img"
                      onClick={() => navigate('/main')}
                      sx={{height: '2.5rem', cursor: 'pointer'}}
                      alt="Digistaff Logo"
                      src={NewDigistaffLogoWhite}
                      display="flex"
                      alignItems="center"
                    />
                  </Grid>
                </Stack>
              </Grid>

              <Grid
                item
                lg={4}
                sx={{
                  display: {
                    xs: 'none',
                    md: 'flex',
                  },
                  justifyContent: 'center',
                }}
              >
                <Box
                  component="img"
                  onClick={() => navigate('/stats')}
                  sx={{height: '4.5rem', cursor: 'pointer'}}
                  alt="Digistaff Logo"
                  src={NewDigistaffLogoFullWhite}
                  display="flex"
                  alignItems="center"
                />
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                sx={{
                  justifyContent: 'flex-end',
                  display: {
                    xs: open ? 'none' : 'flex',
                    md: 'flex',
                  },
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <AppsMenu />
                  <AccountMenu />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            backgroundColor: '#7bb7f8',
            display: {
              xs: open ? 'flex' : 'none',
              md: 'flex',
            },
          }}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {/*
             * TODO: Change from checking for an empty string to
             * checking if the array of businesses is empty
             */}
            <Box onClick={handleDrawerOpen}>
              {sideBarItemList.map((sideBarItem: any) => (
                <NestedList
                  key={sideBarItem.unCollapsedItem.name}
                  unCollapsedItem={sideBarItem.unCollapsedItem}
                  collapsedItems={sideBarItem.collapsedItems}
                />
              ))}
            </Box>
          </List>
          <Divider />
        </Drawer>
        <Box
          sx={{
            flexGrow: 1,
            p: {
              xs: 2,
              md: 5,
            },
          }}
        >
          <DrawerHeader />
          <Typography variant="h4" component="h2" sx={{marginBottom: '30px'}}>
            {title}
          </Typography>
          {children}
        </Box>
      </Box>
    </>
  );
};
