import React from 'react';
import {createRoot} from 'react-dom/client';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import 'moment-timezone';
import {appTheme} from './Theme';
import {CssBaseline, ThemeProvider} from '@mui/material';
import '../assets/scss/app.scss';
import {publicRoutes} from './routes/public';
import {privateRoutes} from './routes/private';
import {AuthProvider} from './providers/AuthProvider';
import {Provider} from '@rollbar/react';
import {getCurrentLoggedOps} from './providers/api/opsProfile';

const container = document.getElementById('App') as Element;

const reactRoot = createRoot(container, {
  onRecoverableError: error => {
    console.log(`onRecoverableError: ${error}`);
  },
});

const browserRouter = createBrowserRouter([...publicRoutes, ...privateRoutes]);

console.log('CURRENT_APP_VERSION', process.env.CURRENT_APP_VERSION);

const rollbarConfig = {
  accessToken: process.env.ROLLBAR_INTERNAL_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.CLOUD_ENVIRONMENT,
  autoInstrument: false,
  payload: {
    client: {
      javascript: {
        code_version: process.env.CURRENT_APP_VERSION,
        source_map_enabled: true,
      },
    },
    currentAppVersion: process.env.CURRENT_APP_VERSION,
    currentLoggedInOp: getCurrentLoggedOps(),
  },
};

const RootContainer = () => {
  return (
    <React.StrictMode>
      <Provider config={rollbarConfig}>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <AuthProvider>
            <RouterProvider router={browserRouter} />
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

reactRoot.render(<RootContainer />);
