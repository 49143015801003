/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  CircularProgress,
  FormHelperText,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {useEffect, useState} from 'react';
import {createPayrollCACalculation} from '@digistaff/payroll';
import {app} from '../../providers/Account';
import moment from 'moment';

interface Props {
  provinces: string[];
  initalProvince: string;
  type: 'table' | 'formula';
}

export const Calculator = ({provinces, initalProvince, type}: Props) => {
  const [open, setOpen] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState(initalProvince);
  const [income, setIncome] = useState(0);
  const [payFrequency, setPayFrequency] = useState(52);
  const [federalCC, setFederalCC] = useState('CC1');
  const [provincialCC, setProvincialCC] = useState('CC1');
  const [date, setDate] = useState(new Date());
  const [report, setReport] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setSelectedProvince(initalProvince);
  }, [initalProvince]);

  const resetForm = () => {
    setSelectedProvince('');
    setIncome(0);
    setPayFrequency(0);
    setFederalCC('CC1');
    setProvincialCC('CC1');
    setDate(new Date());
    setReport(null);
  };

  const isFormValid = () => {
    const newErrors: any = {};

    if (!provinces.includes(selectedProvince)) newErrors.province = 'Required';
    if (selectedProvince.length < 1) newErrors.province = 'Required';
    if (income < 1) newErrors.income = 'Invalid Value';
    if (payFrequency < 1) newErrors.payFrequency = 'Required';
    if (date === null) newErrors.date = 'Required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const calculate = async () => {
    const payload: any = {
      country: 'ca',
      currency: 'cad',
      province: selectedProvince,
      income: Math.round(income * 100),
      pay_period_frequency: payFrequency,
      federal_claim_code: federalCC,
      provincial_claim_code: provincialCC,
      payroll_date: moment(date).toISOString(),
    };

    if (type === 'table') payload.calculation_method = 'T4032';
    else if (type === 'formula') payload.calculation_method = 'T4127';

    const result = await createPayrollCACalculation(app, payload);
    const response = result.data.createPayrollCACalculation;

    if (response.id !== null) {
      setReport({
        grossIncome: (response.gross_income / 100).toFixed(2),
        provincialTax: (response.provincial_tax_deduction / 100).toFixed(2),
        federalTax: (response.federal_tax_deduction / 100).toFixed(2),
        cpp: (response.cpp_deduction / 100).toFixed(2),
        ei: (response.ei_deduction / 100).toFixed(2),
        netIncome: (response.net_income / 100).toFixed(2),
        currency: response?.currency?.toUpperCase(),
      });
    } else {
      setReport({
        error:
          'Could not complete calculation. Please check that all tables have been successfully proccessed',
      });
    }

    setLoading(false);
  };

  const handleCalculateClick = () => {
    if (isFormValid()) {
      calculate();
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Test Calculations
      </Button>
      <GlobalModal
        open={open}
        onClose={() => setOpen(false)}
        defaultHeader={true}
        title="Test Deduction Tables"
        width="50vw"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="provinceLbl">Province</InputLabel>
              <Select
                size="small"
                label="Province"
                labelId="provinceLbl"
                value={selectedProvince}
                error={'province' in errors}
                onChange={event => {
                  setSelectedProvince(event.target.value);
                }}
              >
                {provinces.map(prov => {
                  const formattedProv = prov.toLowerCase().replace(/_/g, ' ');
                  return (
                    <MenuItem value={prov} key={prov}>
                      <Typography textTransform="capitalize">
                        {formattedProv}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
              {'province' in errors && (
                <FormHelperText sx={{color: '#D32F2F'}}>
                  {errors.province}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Income"
              type="number"
              fullWidth
              error={'income' in errors}
              helperText={errors.income}
              value={income}
              onChange={event => setIncome(+(+event.target.value).toFixed(2))}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="payFreqLbl">Pay Period Frequency</InputLabel>
              <Select
                size="small"
                label="Pay Period Frequency"
                labelId="payFreqLbl"
                error={'payFrequency' in errors}
                value={payFrequency}
                onChange={event => {
                  setPayFrequency(+event.target.value);
                }}
              >
                <MenuItem value={26}>Biweekly (26 pay periods a year)</MenuItem>
                <MenuItem value={52}>Weekly (52 pay periods a year)</MenuItem>
              </Select>
              {'payFrequency' in errors && (
                <FormHelperText sx={{color: '#D32F2F'}}>
                  {errors.payFrequency}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="claimCodelbl">Federal Claim Code</InputLabel>
              <Select
                size="small"
                label="Federal Claim Code"
                labelId="claimCodelbl"
                value={federalCC}
                onChange={event => {
                  setFederalCC(event.target.value);
                }}
              >
                <MenuItem value="CC0">CC0</MenuItem>
                <MenuItem value="CC1">CC1</MenuItem>
                <MenuItem value="CC2">CC2</MenuItem>
                <MenuItem value="CC3">CC3</MenuItem>
                <MenuItem value="CC4">CC4</MenuItem>
                <MenuItem value="CC5">CC5</MenuItem>
                <MenuItem value="CC6">CC6</MenuItem>
                <MenuItem value="CC7">CC7</MenuItem>
                <MenuItem value="CC8">CC8</MenuItem>
                <MenuItem value="CC9">CC9</MenuItem>
                <MenuItem value="CC10">CC10</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="claimCodelbl">Provincial Claim Code</InputLabel>
              <Select
                size="small"
                label="Provincial Claim Code"
                labelId="claimCodelbl"
                value={provincialCC}
                onChange={event => {
                  setProvincialCC(event.target.value);
                }}
              >
                <MenuItem value="CC0">CC0</MenuItem>
                <MenuItem value="CC1">CC1</MenuItem>
                <MenuItem value="CC2">CC2</MenuItem>
                <MenuItem value="CC3">CC3</MenuItem>
                <MenuItem value="CC4">CC4</MenuItem>
                <MenuItem value="CC5">CC5</MenuItem>
                <MenuItem value="CC6">CC6</MenuItem>
                <MenuItem value="CC7">CC7</MenuItem>
                <MenuItem value="CC8">CC8</MenuItem>
                <MenuItem value="CC9">CC9</MenuItem>
                <MenuItem value="CC10">CC10</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                renderInput={props => (
                  <TextField
                    {...props}
                    inputProps={{
                      ...props.inputProps,
                      placeholder: 'Payroll Date',
                    }}
                    size="small"
                    label="Payroll Date"
                    fullWidth
                    error={'date' in errors}
                    helperText={errors.date}
                  />
                )}
                value={date}
                inputFormat="DD/MM/YYYY"
                onChange={event => {
                  if (event) setDate(new Date(event));
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" fullWidth onClick={resetForm}>
              Reset
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                setReport(null);
                setLoading(true);
                handleCalculateClick();
              }}
            >
              {loading ? <CircularProgress /> : 'Calculate'}
            </Button>
          </Grid>
        </Grid>
        {report !== null && (
          <>
            {report.error ? (
              <Typography variant="h6" marginTop={3} sx={{color: '#D32F2F'}}>
                {report.error}
              </Typography>
            ) : (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Gross Income</TableCell>
                    <TableCell>
                      <Typography fontWeight="bold" align="right">
                        {report.grossIncome} {report.currency}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Provincial Tax Deduction</TableCell>
                    <TableCell>
                      <Typography fontWeight="bold" align="right">
                        {report.provincialTax} {report.currency}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Federal Tax Deduction</TableCell>
                    <TableCell>
                      <Typography fontWeight="bold" align="right">
                        {report.federalTax} {report.currency}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CPP Deduction</TableCell>
                    <TableCell>
                      <Typography fontWeight="bold" align="right">
                        {report.cpp} {report.currency}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>EI Deduction</TableCell>
                    <TableCell>
                      <Typography fontWeight="bold" align="right">
                        {report.ei} {report.currency}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Net Income</TableCell>
                    <TableCell>
                      <Typography fontWeight="bold" align="right">
                        {report.netIncome} {report.currency}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </>
        )}
      </GlobalModal>
    </>
  );
};
