import {Grid, Card, CardContent, Typography} from '@mui/material';
import {ReportResourceStruct} from '../../types/types';

interface CountChartProps {
  data: ReportResourceStruct;
}
export const CountChart = ({data}: CountChartProps) => {
  return (
    <Grid item xs={6}>
      <Card>
        <CardContent>
          <Typography color="text.secondary" variant="h4" gutterBottom>
            {data.title}
          </Typography>
          <Typography textAlign="center" variant="h1">
            {data.dataset.data}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
