import {AddTenantView} from '../views/AddTenantView/AddTenantView';
import {DeductionsView} from '../views/PayrollView/DeductionsView';
import {FormulaView} from '../views/PayrollView/FormulaView';
import RouteErrorView from '../views/RouteErrorView.tsx/RouteErrorView';
import {StatsView} from '../views/StatsView/StatsView';
import {TenantsView} from '../views/TenantsView/TenantsView';
import {PrivateRoute} from './gaurds/PrivateRoute';

export const privateRoutes = [
  {
    path: '/stats',
    element: (
      <PrivateRoute>
        <StatsView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/tenants',
    element: (
      <PrivateRoute>
        <TenantsView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/tenants/add',
    element: (
      <PrivateRoute>
        <AddTenantView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/payroll/deduction-table',
    element: (
      <PrivateRoute>
        <DeductionsView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/payroll/deduction-formula',
    element: (
      <PrivateRoute>
        <FormulaView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
];
