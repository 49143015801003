import {useCallback, useEffect, useState} from 'react';
import {DataTable} from '../../common/DataTable/DataTable';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {useTenant} from '../../providers/api/useTenant';
import {tenantCols} from './TenantViewData';
import {TenantData} from '../../types/types';
import {Box, Button} from '@mui/material';
import {useNavigate, useLocation} from 'react-router-dom';
import {Toast} from '../../common/Toast/Toast';

export const TenantsView = () => {
  const {listAllTenants} = useTenant();
  const [tenants, setTenants] = useState<TenantData[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {state} = useLocation();
  const [showToast, setShowToast] = useState(false);

  const getData = useCallback(async () => {
    const tenantList: TenantData[] = await listAllTenants();
    setTenants(tenantList);
    setLoading(false);
  }, [listAllTenants]);

  useEffect(() => {
    if (state?.created) {
      setShowToast(true);
    }
  }, [state]);

  return (
    <MainViewContainer title="Tenants">
      <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1.5}}>
        <Button variant="outlined" onClick={() => navigate('/tenants/add')}>
          Add Tenant
        </Button>
      </Box>
      <DataTable
        rows={tenants}
        columns={tenantCols}
        loading={loading}
        rowCount={tenantCols.length}
        getData={getData}
      />
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        severity="success"
        text="Tenant has been created"
        verticalPos="bottom"
        horizontalPos="center"
      />
    </MainViewContainer>
  );
};
