import {createTenant, listTenants} from '@digistaff/business';
import {useCallback} from 'react';
import {app} from '../Account';
import {NewTenantPayload, TenantData} from '../../types/types';

export const useTenant = () => {
  const listAllTenants = useCallback(() => {
    return new Promise<TenantData[]>((resolve, reject) => {
      listTenants(app, {filter: {status: 'active'}})
        .then(res => resolve(res.data.listTenants))
        .catch(err => reject(err));
    });
  }, []);

  const createNewTenant = useCallback((payload: NewTenantPayload) => {
    return new Promise((resolve, reject) => {
      createTenant(app, payload)
        .then(res => resolve(res.data.createTenant.id))
        .catch(err => reject(err));
    });
  }, []);

  return {listAllTenants, createNewTenant};
};
