/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, CircularProgress, Grid, Stack, TextField} from '@mui/material';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {useCallback, useEffect, useState} from 'react';
import {useReports} from '../../providers/api/useReports';
import {ReportStruct} from '../../types/types';
import {GenerateReport} from './GenerateReport';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';

export const StatsView = () => {
  const {listReports} = useReports();
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState<ReportStruct[]>([]);
  const [startDate, setStartDate] = useState<any>(moment().startOf('month'));
  const [endDate, setEndDate] = useState<any>(moment());

  const getReports = useCallback(
    async (start: any, end: any) => {
      const filter = {
        date: {
          operator: 'between',
          value: {
            from: new Date(start).toISOString(),
            to: new Date(end).toISOString(),
          },
        },
      };
      const reports = await listReports(filter);
      setReports(reports);
      setLoading(false);
    },
    [listReports]
  );

  useEffect(() => {
    getReports(startDate, endDate);
  }, [endDate, getReports, startDate]);

  return (
    <MainViewContainer title="Stats">
      <Stack
        direction="row"
        spacing={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '25px',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            renderInput={props => (
              <TextField
                {...props}
                inputProps={{
                  ...props.inputProps,
                  placeholder: 'Start Date',
                }}
                size="small"
                label="Start Date"
              />
            )}
            value={startDate}
            maxDate={endDate}
            inputFormat="DD/MM/YYYY"
            onChange={event => {
              setLoading(true);
              setStartDate(event);
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            renderInput={props => (
              <TextField
                {...props}
                inputProps={{
                  ...props.inputProps,
                  placeholder: 'End Date',
                }}
                size="small"
                label="End Date"
              />
            )}
            value={endDate}
            minDate={startDate}
            maxDate={new Date()}
            inputFormat="DD/MM/YYYY"
            onChange={event => {
              setLoading(true);
              setEndDate(event);
            }}
          />
        </LocalizationProvider>
      </Stack>
      {loading && (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      )}
      <Grid container spacing={2}>
        {!loading &&
          reports.map(report => (
            <GenerateReport key={report.id} report={report} />
          ))}
      </Grid>
    </MainViewContainer>
  );
};
