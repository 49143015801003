import {AttachMoney, Business, QueryStats} from '@mui/icons-material';

export const sideBarItemList = [
  {
    unCollapsedItem: {
      name: 'Stats',
      Icon: QueryStats,
      url: '/stats',
    },
  },
  {
    unCollapsedItem: {
      name: 'Tenants',
      Icon: Business,
      url: '/tenants',
    },
  },
  {
    unCollapsedItem: {
      name: 'Payroll',
      Icon: AttachMoney,
    },
    collapsedItems: [
      {name: 'Deduction Tables', url: '/payroll/deduction-table'},
      {name: 'Deduction Formulas', url: '/payroll/deduction-formula'},
    ],
  },
];
