import {useState} from 'react';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepButton,
  StepContent,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import {ReactMultiEmail} from 'react-multi-email';
import {AutoComplete} from '../../common/AutoComplete/AutoComplete';
import PhoneInput from 'react-phone-number-input';
import {
  verifyAccountType,
  verifyAppointmentAddress,
  verifyTenant,
  verifyUsers,
} from './verify';
import {
  AddressStruct,
  ErrorStruct,
  NewTenantPayload,
  ToastStruct,
} from '../../types/types';
import {useTenant} from '../../providers/api/useTenant';
import {Toast} from '../../common/Toast/Toast';
import {useNavigate} from 'react-router-dom';

export const AddTenantView = () => {
  const [activeStep, setActiveStep] = useState(0);
  interface TenantState {
    account_type: string;
    name: string;
    contact_address: AddressStruct | null;
    contact_name: string;
    contact_email: string;
    contact_phone: string;
    initial_admin_users: string[];
    initial_supervisor_users: string[];
    appointment_address: AddressStruct | null;
  }
  const [state, setState] = useState<TenantState>({
    account_type: '',
    name: '',
    contact_address: null,
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    initial_admin_users: [
      'yash@digistaff.ca',
      'lucky@digistaff.ca',
      'mak@digistaff.ca',
    ],
    initial_supervisor_users: [],
    appointment_address: null,
  });
  const [errors, setErrors] = useState<ErrorStruct>({});
  const [showSubmit, setShowSubmit] = useState(false);
  const {createNewTenant} = useTenant();
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const steps = [
    {
      label: 'Account Type',
      description: (
        <>
          <FormControl>
            <RadioGroup
              name="accountType"
              value={state.account_type}
              onChange={event =>
                setState({...state, account_type: event.target.value})
              }
            >
              <FormControlLabel
                value="agency"
                control={<Radio />}
                label="Agency"
              />
              <FormControlLabel
                value="independent_business"
                control={<Radio />}
                label="Independent Business"
              />
            </RadioGroup>
            {'account_type' in errors && (
              <FormHelperText sx={{color: 'red'}}>
                {errors.account_type}
              </FormHelperText>
            )}
          </FormControl>
        </>
      ),
    },
    {
      label: 'Tenant Information',
      description: (
        <Stack spacing={1}>
          <TextField
            size="small"
            label="Tenant Name"
            value={state.name}
            onChange={event => setState({...state, name: event.target.value})}
          />
          <TextField
            size="small"
            label="Contact Name"
            value={state.contact_name}
            onChange={event =>
              setState({...state, contact_name: event.target.value})
            }
          />
          <AutoComplete
            placeHolder="Tenant Address"
            initialValue={
              state.contact_address
                ? `${state.contact_address.street}, ${state.contact_address.city}, ${state.contact_address.province} ${state.contact_address.postal}, ${state.contact_address.country}`
                : ''
            }
            onChange={address => setState({...state, contact_address: address})}
          />
          <TextField
            size="small"
            label="Contact Email"
            value={state.contact_email}
            onChange={event =>
              setState({...state, contact_email: event.target.value})
            }
          />
          <PhoneInput
            placeholder="Contact Phone"
            international
            countryCallingCodeEditable
            value={state.contact_phone}
            limitMaxLength
            onChange={event =>
              setState({...state, contact_phone: event ? event.toString() : ''})
            }
          />
          {'tenant' in errors && (
            <FormHelperText sx={{color: 'red'}}>{errors.tenant}</FormHelperText>
          )}
        </Stack>
      ),
    },
    {
      label: 'Initial Users',
      description: (
        <Stack spacing={1}>
          <Typography color="gray">
            Enter the emails for the admin and supervisor accounts you would
            like created
          </Typography>
          <ReactMultiEmail
            placeholder="Admins"
            emails={state.initial_admin_users}
            style={{height: '75px'}}
            onChange={(emails: string[]) => {
              setState({...state, initial_admin_users: emails});
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
          <ReactMultiEmail
            placeholder="Supervisors"
            emails={state.initial_supervisor_users}
            style={{height: '75px'}}
            onChange={(emails: string[]) => {
              setState({...state, initial_supervisor_users: emails});
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
          {'users' in errors && (
            <FormHelperText sx={{color: 'red'}}>{errors.users}</FormHelperText>
          )}
        </Stack>
      ),
    },
    {
      label: 'Appointment Address',
      description: (
        <>
          <AutoComplete
            placeHolder="Address"
            initialValue={
              state.appointment_address
                ? `${state.appointment_address.street}, ${state.appointment_address.city}, ${state.appointment_address.province} ${state.appointment_address.postal}, ${state.appointment_address.country}`
                : ''
            }
            onChange={address =>
              setState({...state, appointment_address: address})
            }
          />
          {'appointment' in errors && (
            <FormHelperText sx={{color: 'red'}}>
              {errors.appointment}
            </FormHelperText>
          )}
        </>
      ),
    },
  ];

  const handleNext = () => {
    let verify;
    if (activeStep === 0) verify = verifyAccountType(state.account_type);
    else if (activeStep === 1)
      verify = verifyTenant(
        state.name,
        state.contact_name,
        state.contact_address,
        state.contact_email,
        state.contact_phone
      );
    else if (activeStep === 2) verify = verifyUsers(state.initial_admin_users);
    else if (activeStep === 3)
      verify = verifyAppointmentAddress(state.appointment_address);

    if (verify) {
      setErrors(verify);
    } else {
      setErrors({});
      if (activeStep < 3) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else setShowSubmit(true);
    }
  };

  const handleBack = () => {
    setShowSubmit(false);
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    const payload: NewTenantPayload = {
      name: state.name,
      account_type: state.account_type,
      contact_email: state.contact_email,
      contact_name: state.contact_name,
      contact_phone: state.contact_phone,
      appointment_address: JSON.stringify(state.appointment_address),
      city: state.appointment_address!.city,
      country: state.appointment_address!.country,
      postal: state.appointment_address!.postal,
      province: state.appointment_address!.province,
      street: state.appointment_address!.street,
      initial_admin_users: state.initial_admin_users,
      initial_supervisor_users: state.initial_supervisor_users,
    };

    const result = await createNewTenant(payload);
    if (result) {
      navigate('/tenants', {state: {created: true}});
    } else {
      setToastData({
        ...toastData,
        severity: 'error',
        text: 'Error creating tenant',
      });
    }
    setLoading(false);
    setShowToast(true);
  };

  return (
    <MainViewContainer title="Add Tenant">
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepButton>
              <Typography variant="h6">{step.label}</Typography>
            </StepButton>
            <StepContent>
              {step.description}
              <Box sx={{mb: 2}}>
                <div>
                  {!showSubmit && (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{mt: 2, mr: 1}}
                    >
                      Next
                    </Button>
                  )}
                  {index !== 0 && (
                    <Button onClick={handleBack} sx={{mt: 2, mr: 1}}>
                      Back
                    </Button>
                  )}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {showSubmit && (
        <Button
          variant="outlined"
          onClick={() => {
            setLoading(true);
            handleSubmit();
          }}
        >
          {loading ? <CircularProgress /> : 'Submit'}
        </Button>
      )}
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        severity={toastData.severity}
        text={toastData.text}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </MainViewContainer>
  );
};
