/* eslint-disable @typescript-eslint/no-explicit-any */
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useCallback, useContext, useEffect} from 'react';

import {AuthContext} from '../../providers/AuthProvider';

import {CircularProgress, Box} from '@mui/material';
import {
  app,
  setTenantKey,
  signInWithToken,
  getCurrentUser,
} from '../../providers/Account';
import {getOpsProfile} from '@digistaff/business';

export const RootView = () => {
  const {currentUser} = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const getTenant = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      getOpsProfile(app, id)
        .then(res => {
          resolve(res.data.getOpsProfile.current_selected_tenant);
        })
        .catch(err => reject(err));
    });
  }, []);

  useEffect(() => {
    const navigateToAssignedRoute = () => {
      navigate(localStorage.getItem('dsLastPage') || '/stats');
    };

    const handleAuthentication = async () => {
      if (searchParams.get('idToken')) {
        signInWithToken(
          app,
          searchParams.get('username') as string,
          searchParams.get('idToken') as string,
          searchParams.get('accessToken') as string,
          searchParams.get('refreshToken') as string
        );
        let tenant: any = searchParams.get('tenant') as string;
        const continueURL = searchParams.get('continue');

        if (!tenant) tenant = await getTenant(searchParams.get('username')!);

        if (tenant !== null) {
          setTenantKey(app, tenant);
          localStorage.setItem('tenant', tenant);
        }
        if (continueURL) localStorage.setItem('dsLastPage', continueURL);
      } else {
        getCurrentUser(app).catch(() => {
          const isLocal =
            window.location.origin === process.env.LOCAL_INTERNAL_URL;
          const redirectBaseUrl = isLocal
            ? process.env.LOCAL_ACCOUNTS_URL
            : process.env.DS_ACCOUNTS_URL;

          window.location.href = `${redirectBaseUrl}`;
        });
      }
    };

    if (currentUser.user) navigateToAssignedRoute();
    else handleAuthentication();
  }, [currentUser, searchParams, navigate, getTenant]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    </>
  );
};
