/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect, useCallback} from 'react';
import {createContext, useState} from 'react';
import {fetchOpsProfile} from './api/opsProfile';
import {app, getCurrentToken, signOut} from './Account';

export interface AuthContextInterface {
  currentUser: any;
  login: Function;
  logOut: Function;
  opsProfile: any;
}

export const AuthContext = createContext<AuthContextInterface>(null!);

export const AuthProvider = ({children}: {children: React.ReactNode}) => {
  const [currentUser, setCurrentUser] = useState({});
  const [opsProfile, setOpsProfile] = useState({});

  const logOut = useCallback(() => {
    signOut(app)
      .then(() => {
        localStorage.clear();
        handleAuthRedirect();
      })
      .catch(err => console.log(err));
  }, []);

  const login = useCallback(
    (user: any) => {
      const opsId = user?.Username;
      fetchOpsProfile(opsId)
        .then(res => {
          if (!res?.is_internal_user) logOut();

          setOpsProfile(res);
          setCurrentUser({user});
        })
        .catch(err => {
          console.log(err);
        });
    },
    [logOut]
  );

  const handleAuthRedirect = () => {
    const isLocal = window.location.origin === process.env.LOCAL_INTERNAL_URL;
    const redirectBaseUrl = isLocal
      ? process.env.LOCAL_ACCOUNTS_URL
      : process.env.DS_ACCOUNTS_URL;

    window.location.href = `${redirectBaseUrl}`;
  };

  useEffect(() => {
    const currentStoredUser = getCurrentToken(app);
    if (currentStoredUser.username) {
      login({Username: currentStoredUser.username});
    } else handleAuthRedirect();
  }, [login]);

  return (
    <AuthContext.Provider value={{currentUser, login, logOut, opsProfile}}>
      {children}
    </AuthContext.Provider>
  );
};
