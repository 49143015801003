import {Card, Typography} from '@mui/material';
import {ReportResourceStruct} from '../../types/types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface LineChartProps {
  data: ReportResourceStruct;
}
export const LineChart = ({data}: LineChartProps) => {
  const formattedLabels = data.labels.map(label =>
    moment(label).format('DD/MM/YYYY')
  );
  const graphData = {
    labels: formattedLabels,
    datasets: [
      {
        data: data.dataset.data,
        label: data.dataset.label,
        borderColor: 'rgb(62, 130, 191)',
        backgroundColor: 'rgb(62, 130, 191, 0.7)',
        fill: true,
      },
    ],
  };

  const config = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: '',
        font: {size: 20},
      },
    },
    centerText: '',
  };

  const options = JSON.parse(JSON.stringify(config));
  return (
    <Card
      sx={{
        width: '100%',
        height: 1,
        padding: '20px',
        overflow: 'none',
        borderRadius: 0,
      }}
    >
      <Typography color="text.secondary" variant="h4" gutterBottom>
        {data.title}
      </Typography>
      <Line options={options} data={graphData} />
    </Card>
  );
};
