import {ReportStruct} from '../../types/types';
import {CountChart} from './CountChart';
import {LineChart} from './LineChart';
import {Grid} from '@mui/material';

interface GenerateReportProps {
  report: ReportStruct;
}
export const GenerateReport = ({report}: GenerateReportProps) => {
  if (report.type === 'count')
    return <CountChart data={JSON.parse(report.resource)} />;
  else if (report.type === 'lineChart') {
    return (
      <Grid item xs={12} sx={{height: '500px'}}>
        <LineChart data={JSON.parse(report.resource)} />
      </Grid>
    );
  } else return <></>;
};
