import {GridColDef} from '@mui/x-data-grid';
import moment from 'moment';

export const tenantCols: GridColDef[] = [
  {field: 'id', headerName: 'Id', width: 200},
  {field: 'name', headerName: 'Name', width: 250},
  {field: 'businesses', headerName: 'Businesses', width: 125},
  {field: 'businesse_locations', headerName: 'Business Locations', width: 150},
  {field: 'staff', headerName: 'Staff', width: 125},
  {field: 'contact_name', headerName: 'Contact Name', width: 200},
  {field: 'contact_email', headerName: 'Contact Email', width: 200},
  {field: 'contact_phone', headerName: 'Contact Phone', width: 200},
  {
    field: 'location',
    headerName: 'Location',
    width: 450,
    renderCell: data =>
      `${data.row.street}, ${data.row.city} ${data.row.postal}, ${data.row.country}`,
  },
  {
    field: 'created_at',
    headerName: 'Created On',
    width: 125,
    renderCell: data => moment(data.row.created_at).format('DD/MM/YYYY'),
  },
];
