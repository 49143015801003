import {CancelRounded} from '@mui/icons-material';
import {Box, Divider, Modal, Stack, Typography} from '@mui/material';
import {popupCloseBtn, popupModal} from './globalModalStyles';

interface GlobalModalProps {
  open: boolean;
  onClose: (open: boolean) => void;
  children: React.ReactNode;
  title?: string;
  defaultHeader: boolean;
  width?: string;
  height?: string;
  maxHeight?: string;
}

/**
 *
 * @param open - Whether to show the modal or not
 * @param onClose - Handles what happens when the component is closed
 * @param children - JSX element to render in the modal
 * @param title - Optional title of the modal
 * @param defaultHeader - Flag whether or not to render the default header (title & close icon)
 * @param width - Optional width of the modal. auto if no value is provided
 * @param height - Optional height of the modal. auto if no value is provided
 */
export const GlobalModal = ({
  open,
  onClose,
  children,
  title,
  defaultHeader,
  width,
  height,
  maxHeight,
}: GlobalModalProps) => {
  const isMobile = window.screen.width < 900;

  const handleClose = (
    event?: {},
    reason?: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason && reason === 'backdropClick') return;
    onClose(false);
  };

  const getBrowserName = () => {
    const browserInfo = navigator.userAgent;
    let browser;
    if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
      browser = 'Opera';
    } else if (browserInfo.includes('Edg')) {
      browser = 'Edge';
    } else if (browserInfo.includes('Chrome')) {
      browser = 'Chrome';
    } else if (browserInfo.includes('Safari')) {
      browser = 'Safari';
    } else if (browserInfo.includes('Firefox')) {
      browser = 'Firefox';
    } else {
      browser = 'unknown';
    }
    return browser;
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Stack
        sx={popupModal}
        width={isMobile ? '95vw' : width ? width : 'auto'}
        height={height ? height : 'auto'}
        minHeight={getBrowserName() === 'Safari' ? '325px' : ''}
        maxHeight={maxHeight ? maxHeight : '95vh'}
      >
        {defaultHeader && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4" marginBottom="15px">
                {title}
              </Typography>
              <CancelRounded
                onClick={() => onClose(false)}
                sx={popupCloseBtn}
              />
            </Stack>
            <Divider />
          </>
        )}
        <Box sx={{overflow: 'auto', padding: '15px'}} height="100%">
          {children}
        </Box>
      </Stack>
    </Modal>
  );
};
