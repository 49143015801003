/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  fetchOpsProfile,
  getCurrentLoggedOps,
  logOut,
} from '../../providers/api/opsProfile';

export const PrivateRoute = ({children}: any) => {
  fetchOpsProfile(getCurrentLoggedOps())
    .then(res => {
      if (!res.is_internal_user) logOut();
    })
    .catch(err => console.log(err));

  return children;
};
