import {listRootReports} from '@digistaff/business';
import {useCallback} from 'react';
import {app} from '../Account';
import {ReportStruct} from '../../types/types';

export const useReports = () => {
  const listReports = useCallback((filter: Object) => {
    return new Promise<ReportStruct[]>((resolve, reject) => {
      listRootReports(app, {filter})
        .then(res => resolve(res.data.listRootReports))
        .catch(err => reject(err));
    });
  }, []);

  return {listReports};
};
