/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  Typography,
  Button,
  CircularProgress,
  Link,
  Tooltip,
  TextField,
  FormHelperText,
  Box,
} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {Refresh, UploadFile, Visibility} from '@mui/icons-material';
import {
  createPayrollDeductionType,
  listPayrollCountries,
  listPayrollDeductionGroups,
  listPayrollDeductionTypes,
} from '@digistaff/payroll';
import {app} from '../../providers/Account';
import {useFileUpload} from '../../providers/api/useFileUpload';
import {ToastStruct} from '../../types/types';
import {Toast} from '../../common/Toast/Toast';
import moment from 'moment';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {Calculator} from './Calculator';

interface Props {
  type: 'table' | 'formula';
}

export const Deductions = ({type}: Props) => {
  const [countries, setCountries] = useState<any>([]);
  const [provinces, setProvinces] = useState<any>(null);
  const [docTypes, setDocTypes] = useState<any>(null);
  const [provinceTypes, setProvinceTypes] = useState<any>(null);
  const [countryTypes, setCountryTypes] = useState<any>(null);
  const [deductionTypes, setDeductionTypes] = useState<any>(null);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const {handleFileUpload, changeHandler, fileSelected} = useFileUpload();
  const [showUpload, setShowUpload] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingProvinces, setLoadingProvinces] = useState(false);
  const [loadingType, setLoadingType] = useState(false);
  const [date, setDate] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});
  const [calcProv, setCalcProv] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });
  const [docFormat, setDocFormat] = useState('');

  const getDeductionGroup = useCallback(
    async (country: string) => {
      let filter = {};

      if (type === 'table') {
        filter = {country_id: country, calculation_method: 'T4032'};
        setDocFormat('.pdf');
      } else if (type === 'formula') {
        filter = {country_id: country, calculation_method: 'T4127'};
        setDocFormat('.csv');
      }

      const result = await listPayrollDeductionGroups(app, {filter});

      let provinceGroup: any;
      let countryGroup: any;

      if (
        result.data.listPayrollDeductionGroups[0].provinces.includes(
          'COUNTRY_WIDE'
        )
      ) {
        countryGroup = result.data.listPayrollDeductionGroups[0];
        provinceGroup = result.data.listPayrollDeductionGroups[1];
      } else {
        countryGroup = result.data.listPayrollDeductionGroups[1];
        provinceGroup = result.data.listPayrollDeductionGroups[0];
      }

      const pType = JSON.parse(provinceGroup.types);
      const cType = JSON.parse(countryGroup.types);

      setProvinceTypes(pType);
      setCountryTypes(cType);

      setCalcProv(provinceGroup.provinces);
      setProvinces([...countryGroup.provinces, ...provinceGroup.provinces]);
      setLoadingProvinces(false);
    },
    [type]
  );

  const getDocTypes = useCallback(
    async (province: string) => {
      let filter = {};

      if (type === 'table') {
        filter = {
          country: selectedCountry,
          province: [province],
          calculation_method: 'T4032',
        };
      } else if (type === 'formula') {
        filter = {
          country: selectedCountry,
          province: [province],
          calculation_method: 'T4127',
        };
      }

      const result = await listPayrollDeductionTypes(app, {filter});

      setDeductionTypes(result.data.listPayrollDeductionTypes);

      const currentTypes: any = [];
      if (province === 'COUNTRY_WIDE') {
        countryTypes[0]?.forEach((type: string) => {
          currentTypes.push(`${type}`);
        });

        countryTypes[26]?.forEach((type: string) => {
          currentTypes.push(`${type}_26`);
        });

        countryTypes[52]?.forEach((type: string) => {
          currentTypes.push(`${type}_52`);
        });
      } else {
        provinceTypes[0]?.forEach((type: string) => {
          if (type.indexOf(province) > -1) currentTypes.push(`${type}`);
        });

        provinceTypes[26]?.forEach((type: string) => {
          if (type.indexOf(province) > -1) currentTypes.push(`${type}_26`);
        });

        provinceTypes[52]?.forEach((type: string) => {
          if (type.indexOf(province) > -1) currentTypes.push(`${type}_52`);
        });
      }

      setDocTypes(currentTypes);
      setLoadingType(false);
    },
    [countryTypes, provinceTypes, selectedCountry, type]
  );

  const getCountries = useCallback(
    async (prov?: string) => {
      const result = await listPayrollCountries(app);

      setCountries(result.data.listPayrollCountries);
      setLoadingCountries(false);

      if (prov) {
        getDeductionGroup(selectedCountry);
        setSelectedProvince(prov);
        getDocTypes(prov);
      } else if (selectedCountry.length === 0) {
        setLoadingProvinces(true);
        setSelectedCountry(result.data.listPayrollCountries[0].id);
        getDeductionGroup(result.data.listPayrollCountries[0].id);
      }
    },
    [getDeductionGroup, getDocTypes, selectedCountry]
  );

  useEffect(() => {
    if (
      selectedCountry.length > 0 &&
      provinces !== null &&
      selectedProvince.length === 0
    ) {
      const lastProvince = localStorage.getItem('last_selected_province');
      setLoadingType(true);
      if (lastProvince) {
        setSelectedProvince(lastProvince);
        getDocTypes(lastProvince);
      } else {
        setSelectedProvince('COUNTRY_WIDE');
        getDocTypes('COUNTRY_WIDE');
      }
    }
  }, [getDocTypes, provinces, selectedCountry.length, selectedProvince.length]);

  const closeModal = useCallback(() => {
    setShowUpload(false);
    setDate(null);
    setErrors({});
    changeHandler(null);
  }, [changeHandler]);

  const createDeductionType = useCallback(
    async (url: string) => {
      const payload = {
        document_url: url,
        effective_date: new Date(date).toISOString(),
        name: selectedType,
        status: 'processing',
      };

      const result = await createPayrollDeductionType(app, payload);
      setUploading(false);
      closeModal();
      getCountries(selectedProvince);
      if (result.data.createPayrollDeductionType.id) {
        setToastData({
          ...toastData,
          severity: 'success',
          text: 'Payroll deduction table has been uploaded and is being processed. This can take up to 30 min',
        });
      } else {
        setToastData({
          ...toastData,
          severity: 'error',
          text: 'Could not upload deduction table',
        });
      }
      setShowToast(true);
    },
    [closeModal, date, getCountries, selectedProvince, selectedType, toastData]
  );

  const uploadFile = useCallback(async () => {
    const uploaded: any = await handleFileUpload('');
    const url = uploaded.data.result.Location;
    createDeductionType(url);
  }, [createDeductionType, handleFileUpload]);

  const handleSaveClicked = () => {
    if (validateForm()) {
      setUploading(true);
      uploadFile();
    }
  };

  const getStatusColour = (status: string): string => {
    switch (status) {
      case 'empty':
        return 'gray';
      case '-':
        return 'gray';
      case 'processing':
        return 'orange';
      case 'failed':
        return 'red';
      case 'expired':
        return 'red';
      case 'completed':
        return 'green';
      default:
        return 'black';
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (date === null) newErrors.date = 'Required';
    if (!fileSelected) newErrors.file = 'Required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const refresh = () => {
    setLoadingType(true);
    getDocTypes(selectedProvince);
  };

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    if (deductionTypes !== null && selectedType.length > 0) {
      const info = deductionTypes.find(
        (type: any) => type.name === selectedType
      );

      const newDate = new Date();
      if (info && info.status !== 'failed') {
        newDate.setFullYear(newDate.getFullYear() + 1);
        if (moment(new Date()).dayOfYear() < 182) newDate.setMonth(6, 1);
        else newDate.setMonth(0, 1);
      } else {
        if (moment(new Date()).dayOfYear() < 182) newDate.setMonth(0, 1);
        else newDate.setMonth(6, 1);
      }

      setDate(newDate);
    }
  }, [deductionTypes, selectedType]);

  return (
    <>
      <Stack spacing={1}>
        {loadingCountries ? (
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress />
          </Box>
        ) : (
          <FormControl size="small" fullWidth>
            <InputLabel id="countriesLbl">Country</InputLabel>
            <Select
              size="small"
              label="Country"
              labelId="countriesLbl"
              value={selectedCountry}
              onChange={event => {
                setLoadingProvinces(true);
                setSelectedCountry(event.target.value);
                getDeductionGroup(event.target.value);
              }}
            >
              {countries.map((country: any) => (
                <MenuItem value={country.id} key={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {selectedCountry.length > 0 && provinces !== null && (
          <>
            {loadingProvinces ? (
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                <FormControl size="small" fullWidth>
                  <InputLabel id="provinceLbl">Province</InputLabel>
                  <Select
                    size="small"
                    label="Province"
                    labelId="ProvinceLbl"
                    value={selectedProvince}
                    onChange={event => {
                      setLoadingType(true);
                      setSelectedProvince(event.target.value);
                      getDocTypes(event.target.value);
                      localStorage.setItem(
                        'last_selected_province',
                        event.target.value
                      );
                    }}
                  >
                    {provinces.map((prov: string) => {
                      const formattedProv = prov
                        .toLowerCase()
                        .replace(/_/g, ' ');
                      return (
                        <MenuItem value={prov} key={prov}>
                          <Typography textTransform="capitalize">
                            {formattedProv}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '15px',
                  }}
                >
                  <Stack
                    direction="row"
                    sx={{alignItems: 'center'}}
                    spacing={2}
                  >
                    {selectedProvince.length > 0 && (
                      <Tooltip title="Refresh">
                        <Refresh
                          sx={{fontSize: '2em', cursor: 'pointer'}}
                          onClick={refresh}
                        />
                      </Tooltip>
                    )}
                    <Calculator
                      provinces={calcProv}
                      initalProvince={selectedProvince}
                      type={type}
                    />
                  </Stack>
                </Box>
              </Box>
            )}
          </>
        )}

        {selectedProvince.length > 0 && (
          <>
            {docTypes === null || loadingType ? (
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Uploaded Date</TableCell>
                    <TableCell>Latest Effective Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {docTypes.map((type: any) => {
                    const existing = deductionTypes.find(
                      (dt: any) => dt.name === type
                    );
                    let status = '-';
                    if (existing) {
                      const currentYear = moment(new Date()).get('year');
                      const effectiveYear = moment(existing.effective_date).get(
                        'year'
                      );
                      if (currentYear > effectiveYear) status = 'Expired';
                      else status = existing.status;
                    }
                    return (
                      <TableRow key={type}>
                        <TableCell sx={{fontWeight: 'bold'}}>{type}</TableCell>
                        <TableCell>
                          {existing
                            ? moment(existing.created_at).format(
                                'hh:mm A DD/MM/YYYY'
                              )
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {existing
                            ? moment(existing.effective_date).format(
                                'DD/MM/YYYY'
                              )
                            : '-'}
                        </TableCell>
                        <TableCell>
                          <Typography
                            textTransform="capitalize"
                            color={getStatusColour(status)}
                          >
                            {status}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Stack
                            spacing={1}
                            direction="row"
                            sx={{display: 'flex', alignItems: 'center'}}
                          >
                            {existing?.status !== 'processing' && (
                              <Tooltip title="Upload File">
                                <UploadFile
                                  sx={{fontSize: '30px', cursor: 'pointer'}}
                                  onClick={() => {
                                    setSelectedType(type);
                                    console.log(docTypes);
                                    console.log(type);
                                    setShowUpload(true);
                                  }}
                                />
                              </Tooltip>
                            )}
                            {existing && existing?.status !== 'empty' && (
                              <Tooltip title="Download File">
                                <Link href={existing.document_url} color="#000">
                                  <Visibility
                                    sx={{fontSize: '30px', cursor: 'pointer'}}
                                  />
                                </Link>
                              </Tooltip>
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </>
        )}
      </Stack>
      {showUpload && (
        <GlobalModal
          open={showUpload}
          onClose={closeModal}
          defaultHeader={true}
          title={`Upload ${selectedType}`}
          width="50vw"
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                fontWeight: 'bold',
              }}
            >
              Accepted Formats: {docFormat}
            </Typography>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  renderInput={props => (
                    <TextField
                      {...props}
                      inputProps={{
                        ...props.inputProps,
                        placeholder: 'Effective Date',
                      }}
                      size="small"
                      label="Effective Date"
                    />
                  )}
                  value={date}
                  inputFormat="DD/MM/YYYY"
                  onChange={event => {
                    setDate(event);
                  }}
                />
              </LocalizationProvider>
              {'date' in errors && (
                <FormHelperText sx={{color: '#D32F2F'}}>
                  {errors.date}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth>
              <Button component="label" variant="contained">
                <input
                  type="file"
                  accept={docFormat}
                  onChange={event => {
                    changeHandler(event);
                  }}
                />
              </Button>
              {'file' in errors && (
                <FormHelperText sx={{color: '#D32F2F'}}>
                  {errors.file}
                </FormHelperText>
              )}
            </FormControl>
            {selectedType.includes('FEDERAL') && type === 'table' && (
              <Typography fontStyle="italic" color="gray" textAlign="right">
                You can upload any provincial tax document for federal tax
              </Typography>
            )}
            <FormControl fullWidth>
              <Button variant="outlined" onClick={handleSaveClicked}>
                {uploading ? <CircularProgress /> : 'Save'}
              </Button>
            </FormControl>
          </Stack>
        </GlobalModal>
      )}
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        severity={toastData.severity}
        text={toastData.text}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </>
  );
};
